/* eslint no-param-reassign: ["error", { "props": false }] */
export function closeMenuDropdowns() {
  Array.from(document.querySelectorAll('nav .accordion-js .checked')).forEach(label => {
    label.classList.remove('checked');
    label.querySelector('input').checked = false;
    label.querySelector('.menu-item-with-dropdown').setAttribute('aria-expanded', false);
  });
}

export function openMenuDropdown(event) {
  const input = event.target.parentElement.querySelector('input');
  if (input.checked) return;

  closeMenuDropdowns();
  input.checked = true;
  event.target.setAttribute('aria-expanded', true);
  event.target.parentElement.classList.toggle('checked');
}
