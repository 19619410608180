import { closeMenuDropdowns, openMenuDropdown } from './header/helper';

export default class Spaceable {
  constructor() {
    this.name = 'Spaceable';
    this.key = 'Space';
    this.eventHandler = event => {
      if (event.target.getAttribute('aria-haspopup') === 'true') {
        event.preventDefault();
        if (event.target.getAttribute('aria-expanded') === 'true') {
          closeMenuDropdowns();
        } else {
          openMenuDropdown(event);
        }
      }
    };
  }
}
