/* eslint-disable no-underscore-dangle */

export default class Tabable {
  /**
   * This plugin helps handle tabbing through the DOM.
   *
   * @param {Array} navigableElements contains the elements that are focusable.
   */
  constructor(navigableElements) {
    // required for plugin registration.
    this.name = 'Tabable';
    this.key = 'Tab';
    this.eventHandler = event => {
      const shouldNotHandle = this._elementInFocusId === 1 && event.shiftKey; // If it is trying to tab out of the page, the event should trigger normally
      if (!shouldNotHandle) {
        event.preventDefault();
        if (event.shiftKey) {
          this.focusOnPreviousItem();
        } else {
          this.focusOnNextItem();
        }
      }
    };
    // internal reference to the elements that are focusable.
    this.tabableElements = navigableElements;
    this._elementInFocusId = Math.max(this.tabableElements.indexOf(document.activeElement), 0);
  }

  /**
   * Gets the index of the element in focus.
   */
  get elementInFocusId() {
    const activeElementIdx = this.tabableElements.indexOf(document.activeElement);
    // In case the active element is too far away from the current element in focus, we should reconcile those.
    if (activeElementIdx !== -1 && Math.abs(activeElementIdx - this._elementInFocusId) > 1) {
      this._elementInFocusId = activeElementIdx;
    }
    return this._elementInFocusId;
  }

  /**
   * Sets the index of the element in focus. This is a circular list.
   */
  set elementInFocusId(id) {
    if (id < 0) {
      this._elementInFocusId = this.tabableElements.length - 1;
    } else if (id >= this.tabableElements.length) {
      this._elementInFocusId = 0;
    } else {
      this._elementInFocusId = id;
    }
  }

  /**
   * Set focus on the next item.
   */
  focusOnNextItem() {
    this.focusOnItem(id => id + 1);
  }

  /**
   * Set focus on the previous item.
   */
  focusOnPreviousItem() {
    this.focusOnItem(id => id - 1);
  }

  /**
   * Implements the focus on item logic.
   *
   * @param {function} transform function that transforms the index.
   */
  focusOnItem(transform) {
    let elemIdToFocus = transform(this.elementInFocusId);
    while (!Tabable.isVisible(this.tabableElements[elemIdToFocus])) {
      elemIdToFocus = transform(elemIdToFocus);
    }
    this.elementInFocusId = elemIdToFocus;
    this.tabableElements[elemIdToFocus].focus();
  }

  /**
   * Checks if the element is visible to the user, by checking recursively if the element or any of its
   * parents (up to 'document') are hidden.
   *
   * @param {HTMLElement} element to be checked.
   * @returns {boolean}
   */
  static isVisible(element) {
    // this will be a lot faster when this happens: https://github.com/w3c/csswg-drafts/issues/4122
    if (element === document) {
      return true;
    }
    const style = window.getComputedStyle(element);
    if (
      style.visibility === 'hidden' ||
      style.display === 'none' ||
      style.opacity === '0' ||
      style.width === '0px' ||
      style.height === '0px'
    ) {
      return false;
    }
    return Tabable.isVisible(element.parentNode);
  }
}
