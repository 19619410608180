/* eslint-disable no-param-reassign */
// NOTE: This file is included in nav.js, making it included on every page.

// There is no need to manually add this to individual pages.
(function onPageLoad() {
  // Accordion hover style should only apply to the unchecked elements - provide
  // a CSS class to make that possible:

  const accordionSelectableSections = Array.from(
    document.querySelectorAll('.accordion-js button:not([name^=compare-table-])')
  );
  const accordionInnerContents = Array.from(document.querySelectorAll('.wallet-accordion-item'));
  accordionInnerContents.map(innerParagraph => {
    return innerParagraph.classList.add('hide-element');
  });

  let buttonTabbed = false;
  const handleDirectionalKeys = (
    handleTurnPosition,
    indexOfTurnPosition,
    commonDisplacement,
    activeElement,
    indexOfActiveElement
  ) => {
    if (accordionSelectableSections.includes(activeElement)) {
      if (indexOfActiveElement === handleTurnPosition) {
        accordionSelectableSections[indexOfTurnPosition].focus();
      } else {
        accordionSelectableSections[commonDisplacement].focus();
        if (commonDisplacement === indexOfActiveElement - 1) {
          window.scrollBy(0, -50);
        } else {
          window.scrollBy(0, 50);
        }
      }
    }
  };
  const toggleHiddenElements = activeElement => {
    const selectedInnerParagraph = activeElement.querySelector('.wallet-accordion-item');
    if (!selectedInnerParagraph) return;
    if (activeElement.getAttribute('aria-expanded') === 'true') {
      activeElement.setAttribute('aria-expanded', 'false');
      selectedInnerParagraph.classList.add('hide-element');
    } else {
      activeElement.setAttribute('aria-expanded', 'true');
      selectedInnerParagraph.classList.remove('hide-element');
    }
  };

  window.addEventListener('keydown', e => {
    const { activeElement } = document;
    const indexOfActiveElement = accordionSelectableSections.indexOf(activeElement);
    if (accordionSelectableSections.includes(activeElement)) {
      switch (e.key) {
        case 'Enter': {
          e.preventDefault();
          activeElement.classList.toggle('checked');
          toggleHiddenElements(activeElement);
          break;
        }
        case ' ': {
          e.preventDefault();
          activeElement.classList.toggle('checked');
          toggleHiddenElements(activeElement);
          break;
        }
        case 'ArrowUp': {
          e.preventDefault();
          handleDirectionalKeys(
            0,
            accordionSelectableSections.length - 1,
            indexOfActiveElement - 1,
            activeElement,
            indexOfActiveElement
          );
          break;
        }
        case 'ArrowDown': {
          e.preventDefault();
          handleDirectionalKeys(
            accordionSelectableSections.length - 1,
            0,
            indexOfActiveElement + 1,
            activeElement,
            indexOfActiveElement
          );
          break;
        }
        default:
          break;
      }
    }
  });

  Array.from(document.querySelectorAll('.accordion-js button:not([name^=compare-table-])')).forEach(
    input => {
      buttonTabbed = false;
      Array.from(input.closest('.accordion-js').querySelectorAll('button')).forEach(button => {
        button.setAttribute('tabindex', '0');
        button.setAttribute('aria-expanded', 'false');

        const selectedInnerParagraph = button.querySelector('.wallet-accordion-item');

        input.addEventListener('click', () => {
          input.closest('button').classList.toggle('checked');
          toggleHiddenElements(input.closest('button'));
        });

        button.addEventListener('focus', () => {
          if (buttonTabbed) {
            button.classList.add('checked');
          }
          buttonTabbed = false;
        });

        button.addEventListener('focusout', () => {
          button.classList.remove('checked');
          button.setAttribute('aria-expanded', 'false');
          if (selectedInnerParagraph) selectedInnerParagraph.classList.add('hide-element');
        });
      });
    }
  );
  Array.from(document.querySelectorAll('.accordion-js input:not([name^=compare-table-])')).forEach(
    input => {
      input.addEventListener('click', () => {
        Array.from(input.closest('.accordion-js').querySelectorAll('label'))
          .filter(label => label !== input.closest('label'))
          .forEach(label => label.classList.remove('checked'));
        input.closest('label').classList.toggle('checked');
      });
    }
  );
})();
