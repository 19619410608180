import debounce from 'lodash/debounce';
import './accordion';
import setupLazyLoadingAndAnimations from './lazy_loading';
import setupCompatibilityClasses from '../compatibility';
import KeyboardNav from '../keyboardNav';
import { closeMenuDropdowns, openMenuDropdown } from '../header/helper';

function setupDesktopMenu() {
  const navbar = document.getElementById('gusto-navbar');
  const PAGE_OFFSET = 75;
  let closeTimer;
  let openTimer;

  const keyboardNav = new KeyboardNav();
  keyboardNav.init();

  const updateWithinPageClass = () => {
    if (window.pageYOffset <= PAGE_OFFSET && window.hasSeenEoyTimer === 'false') {
      Array.from(document.querySelectorAll('.banner')).forEach(elem =>
        elem.classList.add('banner-full')
      );
    }
    const hasWithinPageClass = navbar.classList.contains('on-scroll');
    if (window.pageYOffset > PAGE_OFFSET && !hasWithinPageClass) {
      navbar.classList.add('on-scroll');
      Array.from(document.querySelectorAll('.banner-full')).forEach(elem =>
        elem.classList.remove('banner-full')
      );
    }
    if (window.pageYOffset <= PAGE_OFFSET && hasWithinPageClass) {
      navbar.classList.remove('on-scroll');
    }
  };

  const closeMenuOnOutsideClick = event => {
    const openMenu = document.querySelector('nav .accordion-js input:checked');
    if (!openMenu) return;

    if (event.target.closest('nav') === null) {
      closeMenuDropdowns();
    }
  };

  const clearCloseTimer = () => {
    clearTimeout(closeTimer);
  };

  const clearOpenTimer = () => {
    clearTimeout(openTimer);
  };

  const startCloseTimer = () => {
    clearOpenTimer();
    closeTimer = setTimeout(closeMenuDropdowns, 200);
  };

  const startOpenTimer = event => {
    openTimer = setTimeout(() => {
      openMenuDropdown(event);
    }, 75);
    clearCloseTimer();
  };

  const openDropdownTab = event => {
    openTimer = setTimeout(() => {
      closeMenuDropdowns();
      event.target.classList.add('checked');
    }, 75);
    clearCloseTimer();
  };

  const isHoveringButtons = () => {
    document.querySelector('.menu-content').classList.add('button-hover');
  };

  const notHoveringButtons = () => {
    document.querySelector('.menu-content').classList.remove('button-hover');
  };

  document.addEventListener('scroll', () => {
    updateWithinPageClass();
    closeMenuDropdowns();
  });

  const menuTitles = Array.from(document.querySelectorAll('nav .menu-item-with-dropdown'));
  menuTitles.forEach(title => {
    title.addEventListener('mouseenter', startOpenTimer);
    title.addEventListener('focus', startOpenTimer);
    title.addEventListener('click', () => false);
    title.addEventListener('blur', startCloseTimer);
    title.addEventListener('mouseleave', startCloseTimer);
  });

  const menuDropdown = Array.from(document.querySelectorAll('nav .expandable-dropdown'));
  const itemDropdown = Array.from(document.querySelectorAll('nav .product-link'));
  const pricingItem =
    document.getElementById('header-nav_item_desktop-pricing') ||
    document.getElementById('header-nav_item_desktop-see_demo');

  menuDropdown.forEach(md => {
    md.addEventListener('keydown', event => {
      if (event.code === 'Space' || event.code === 'Enter') {
        closeMenuDropdowns();
        event.preventDefault();
        openDropdownTab(event);
      }
    });
  });

  pricingItem.addEventListener('keyup', event => {
    if (event.code === 'Tab') {
      closeMenuDropdowns();
    }
  });

  itemDropdown.forEach(item => {
    item.addEventListener('keydown', event => {
      if (event.code === 'Space' || event.code === 'Enter') {
        window.location.href = event.target.href;
      }
    });
  });

  document.addEventListener('click', event => {
    closeMenuOnOutsideClick(event);
    keyboardNav.queryNavigableElements();
  });

  const menuContent = Array.from(document.querySelectorAll('.menu-content'));
  const eoyBanner = document.querySelectorAll('.eoy-countdown');
  const isEoYBannerVisible =
    eoyBanner.length === 1 &&
    parseInt(window.getComputedStyle(eoyBanner[0]).height, 10) > 0 &&
    window.getComputedStyle(eoyBanner[0]).visibility === 'visible';
  menuContent.forEach(mc => {
    if (isEoYBannerVisible) {
      mc.classList.add('banner');
    }
    mc.addEventListener('mouseenter', clearCloseTimer);
    mc.addEventListener('focusin', clearCloseTimer);
    mc.addEventListener('mouseleave', startCloseTimer);
    mc.addEventListener('focusout', startCloseTimer);
    mc.addEventListener('click', closeMenuDropdowns);
  });

  const subMenuButtons = Array.from(document.querySelectorAll('.subnav-buttons'));
  subMenuButtons.forEach(button => {
    button.addEventListener('mouseenter', isHoveringButtons);
    button.addEventListener('mouseleave', notHoveringButtons);
  });

  // Make sure subnav is not closing for accountants and resources
  [
    document.querySelector('.menu-content-accountants'),
    document.querySelector('.menu-content-resources'),
    document.querySelector('.menu-content-built-for-you'),
  ].forEach(selector => {
    if (selector) {
      selector.addEventListener('mouseenter', clearCloseTimer);
      selector.addEventListener('mouseleave', startCloseTimer);
      selector.addEventListener('click', closeMenuDropdowns);
    }
  });

  updateWithinPageClass();
}

function setupMobileMenu() {
  const html = document.querySelector('html');
  const burgerColumn = document.getElementById('gusto-burger-col');
  const searchButtons = Array.from(document.querySelectorAll('[id=site-search]'));
  const mobileNavBarToggle = document.getElementById('mobile-navbar-toggle');

  burgerColumn.addEventListener('click', () => {
    html.classList.toggle('freezePage');
  });

  if (searchButtons.length > 0) {
    searchButtons.forEach(searchButton => {
      searchButton.addEventListener('click', () => {
        if (mobileNavBarToggle.checked) {
          mobileNavBarToggle.checked = false;
        }
      });
    });
  }
}

function updateNavbarUndersideMargin() {
  const navbarHeight = document.getElementById('gusto-navbar').clientHeight;
  const navbarUnderside = document.querySelector('.navbar-underside');
  if (navbarUnderside) {
    navbarUnderside.style.setProperty('margin-bottom', `${navbarHeight}px`, 'important');
  }
}

// This custom events comes from referral.js to listen for when the notification banner is showing/hiding
window.addEventListener('referral-bar-change', () => {
  updateNavbarUndersideMargin();
});

window.addEventListener('notification-bar-change', () => {
  updateNavbarUndersideMargin();
});

// This is updating the margin-bottom on resize with debouce to not have it run all the time
window.addEventListener('resize', debounce(updateNavbarUndersideMargin, 200));

window.addEventListener(
  'load',
  () => {
    setupCompatibilityClasses();
    setupDesktopMenu();
    setupMobileMenu();
    setupLazyLoadingAndAnimations();
  },
  { once: true }
);

window.addEventListener('unload', () => {
  const mobileNavBarToggle = document.getElementById('mobile-navbar-toggle');
  mobileNavBarToggle.checked = false;
});
