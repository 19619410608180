// Provide CSS helpers to control visibility of elements on legacy browsers.
//
// What is a 'legacy browser'?
// - A browser that doesn't support javascript.
// - A browser that doesn't support IntersectionObserver
// - Internet Explorer.
//
// d-legacy-browser: When this class is applied to an element, only display it
// if the browser is considered to be a legacy browser.
//
// d-legacy-browser-none: When this class is applied to an element, hide the
// element if the browser is considered a legacy browser.
export default function setupCompatibilityClasses() {
  if (!('IntersectionObserver' in window)) return;
  if (window.navigator.userAgent.indexOf('Edge') !== -1) return;

  // For browsers that DO support 'modern' features, hide any elements
  // with the 'd-legacy-browser' class.
  Array.from(document.querySelectorAll('.d-legacy-browser')).forEach(el => {
    el.classList.add('d-none');
  });

  // Ensure that any blocks that would be hidden on a legacy browser, are
  // revealed!
  Array.from(document.querySelectorAll('.d-legacy-browser-none')).forEach(el => {
    el.classList.remove('d-legacy-browser-none');
  });
}
