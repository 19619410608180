/* eslint no-param-reassign: ["error", { "props": false }] */
import lozad from 'lozad';

// This file adds support for loading image tags lazily, and provides a facility
// to create simple 'animate on scroll into view' animations. Used in
// conjunction with image_helper.rb and components/animations.scss
//
// Lazy Loading:
//
// To improve page loading lazy loaded images only make HTTP requests to fetch
// their images if they are on screen (or close to the viewport). This
// implemantion works in conjunction with image_helper.rb -- one would define a
// tag using its helpers like so:
//
// = lazy_image_tag('some/image.png')
//
// This helper would define an image tag, and a fallback <noscript> tag:
//
// <image data-src='some/image.png' class='lazy'>
// <noscript>
//   <image src='some/image.png'>
// </noscript>
//
// For browsers that don't support javascript, or don't support
// IntersectionObserver, the default behavior is to immediately load the images.
//
// For modern browsers, no image is initially loaded. When the visitor scrolls
// the tag near its viewpoint, only then is the image tag loaded (using lozad, a
// thin wrapper around the IntersectionObserver class). This is done by setting
// src or srcset to the corresponding data-src or data-srcset attributes defined
// on the image tags.
//
// Animations:
//
// When elements have an 'animation' class, an 'animated' class is added when
// they are scrolled into view. Individual pages can then add custom transition
// based on the presence/absence of the 'animated' class. These animations would
// only occur once on the page -- an 'animated' class is only added once to each
// element tagged with an 'animation' during a page visit.
//
// Several common CSS animation helper classes are predefined in components/animations.scss
//
// For browsers that don't support IntersectionObserver the default behavior is
// to immediately add an 'animated' class - the visitor may or may not see the
// animation behavior, but they will see the 'final state'.
//
// For browsers that don't have javascript enabled the d-legacy-browser and
// d-legacy-browser-none CSS helpers can be used to show alternate elements.
function setupIntersectionObservers() {
  // Load images that are on screen, and also nearly onscreen. When loaded, tag
  // with 'lazy-loaded' data attribute.
  const nearbyElementsObserver = lozad('.lazy', {
    rootMargin: '1500px 0px 500px 0px',
    loaded: el => {
      delete el.dataset.loaded;
      el.dataset.lazyLoaded = true;
      el.onload = () => el.classList.add('lazy-loaded');
      // Lazy loading images that are 'above' the viewer should not animate.
      // (ie, when following an anchor or refresh a page we don't want to give
      // the impression that the images already exist on the page).
      if (el.getBoundingClientRect().y < 0) {
        // elements that would be matched by these CSS selectors should be
        // affected: .lazy.animation and .animation .lazy
        if (el.classList.contains('animation') || el.closest('animation') !== null) {
          el.classList.add('animated');
        }
      }
    },
  });
  nearbyElementsObserver.observe();

  // Trigger animations of elements that come into view. When `threshold` amount
  // of the image is visible in the viewport, add an `.animated` class to any DOM
  // element with the `.animation` class.
  //
  // Fires a 'animated' event when an 'animated' class is added to an element.
  const animationObserver = lozad('.animation', {
    threshold: 0.3,
    load: () => {},
    loaded: el => {
      delete el.dataset.loaded;
      el.classList.add('animated');
      el.dispatchEvent(new CustomEvent('animated'));
    },
  });
  animationObserver.observe();
}

function removeLazyBehaviorAndAnimate() {
  // For browsers that don't support IntersectionObserver, remove lazy loading
  // behavior.
  Array.from(document.querySelectorAll('.lazy')).forEach(lazy => {
    lazy.classList.remove('lazy');
    if (lazy.dataset && lazy.dataset.src) {
      lazy.setAttribute('src', lazy.dataset.src);
    }
    if (lazy.dataset && lazy.dataset.srcset) {
      lazy.setAttribute('srcset', lazy.dataset.srcset);
    }
  });
  // Simulate the animated state so that these browsers see the final state of
  // animated elements.
  Array.from(document.querySelectorAll('.animation')).forEach(animation => {
    if (animation.classList) {
      animation.classList.add('animated');
    } else if (animation.nodeName === 'svg') {
      // https://caniuse.com/#search=classList
      // IE11 does not implement classList on <svg>
      let appliedClasses = animation.getAttribute('class') || '';
      appliedClasses = !appliedClasses.split(' ').includes('animated')
        ? `${appliedClasses} animated`
        : appliedClasses;
      animation.setAttribute('class', appliedClasses);
    }
  });
}

export default function setupLazyLoadingAndAnimations() {
  if ('IntersectionObserver' in window) {
    setupIntersectionObservers();
  } else {
    removeLazyBehaviorAndAnimate();
  }
}
