import Spaceable from './spaceable';
import Tabable from './tabable';

// navigation plugins to be registered.
const PLUGINS = [Tabable, Spaceable];

export default class KeyboardNav {
  /**
   * Implements the keyboard navigation plugin interface.
   */
  constructor() {
    this.listeners = {};
    this.globalListener = null;
    this.navigableElements = [];
    this.queried = new Set();
    this.queryNavigableElements();
  }

  /**
   * Queries navigable elements and updates navigableElements array for plugins.
   * The only elements we are interested in are the ones that are focusable.
   */
  queryNavigableElements() {
    // Add set to be able to update these without adding multiple listeners to the same events
    const queriedElements = Array.from(
      document.querySelectorAll(
        [
          'a[href]:not([disabled])',
          'button:not([disabled])',
          'input:not([disabled])',
          '[tabindex]:not([disabled]):not([tabindex="-1"])',
        ].join(', ')
      )
    ).filter(element => !this.queried.has(element));

    queriedElements.forEach(element => {
      this.queried.add(element);
      this.navigableElements.push(element);
    });
  }

  /**
   * Initializes the keyboard navigation plugins. Preventing free running code.
   */
  init(plugins = PLUGINS) {
    plugins.forEach(Plugin => {
      const pluginInstance = new Plugin(this.navigableElements);
      // validate plugin instance.
      KeyboardNav.validatePluginInstance(pluginInstance);
      const pluginKey = KeyboardNav.qualifyEventKey(pluginInstance);

      // setting up listeners.
      if (!(pluginKey in this.listeners)) {
        this.listeners[pluginKey] = [];
      }
      this.listeners[pluginKey].push(pluginInstance.eventHandler);
    });

    // setting up global listener for 'keydown' event.
    this.globalListener = document.addEventListener(
      'keydown',
      event => {
        const eventKey = KeyboardNav.qualifyEventKey(event);
        if (eventKey in this.listeners) {
          this.listeners[eventKey].map(listener => listener(event));
        }
      },
      true
    );
  }

  /**
   * validates the plugin instance.
   *
   * @param {object} plugin instance.
   */
  static validatePluginInstance(plugin) {
    const requiredProps = {
      name: 'string',
      key: 'string',
      eventHandler: () => {},
    };
    // runtime type checking.
    Object.entries(requiredProps).forEach(([prop, type]) => {
      if (!plugin[prop] && typeof plugin[prop] !== typeof type) {
        throw new Error(`${plugin.name} must have a ${prop}`);
      }
    });
  }

  /**
   * Generates a qualified key for the plugin.
   *
   * @param {object} input event or plugin instance.
   * @returns {string} qualified key.
   */
  static qualifyEventKey({ key }) {
    if (key === undefined) {
      return undefined;
    }
    if (key === ' ') {
      return 'space';
    }
    return key.toLowerCase();
  }
}
